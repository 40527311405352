<template>
  <div class="tab-box">
    <el-row type="flex" justify="center" class="top-row">
      <el-col :span="12" class="left-col">
        <el-row type="flex" justify="space-around">
          <el-col :span="10" class="left-col">
            <span><b>Total patients: </b>{{ totalPatients }}</span>
            <span><b>Prevalence: </b>{{ prevalence }}</span>
            <span><b>Total multimorbidity: </b>{{ totalComorbidity }}</span>
          </el-col>
          <el-col :span="10" class="left-col">
            <span><b>Disease-SNPs: </b>
              {{ diseaseSNPs }}
              <el-tooltip class="item" effect="light" content="Download disease_snp_sig_ld.txt" placement="top-start">
              <el-link :underline="false" target="_blank" href="../excel/disease_snp_sig_ld.txt">
                <i class="el-icon-download"></i></el-link></el-tooltip>
            </span>
            <span><b>Disease-genes: </b>
              {{ diseaseGenes }}
              <el-tooltip class="item" effect="light" content="Download disease_gene.txt" placement="top-start">
              <el-link :underline="false" target="_blank" href="../excel/disease_gene.txt">
                <i class="el-icon-download"></i></el-link></el-tooltip>
            </span>
            <span><b>Disease-PPI: </b>
              {{ diseasePPI }}
              <el-tooltip class="item" effect="light" content="Download disease_ppi.txt" placement="top-start">
              <el-link :underline="false" target="_blank" href="../excel/disease_ppi.txt">
                <i class="el-icon-download"></i></el-link></el-tooltip>
            </span>
            <span><b>Disease-pathways: </b>
              {{ diseasePathways }}
              <el-tooltip class="item" effect="light" content="Download disease_pathway.txt" placement="top-start">
              <el-link :underline="false" target="_blank" href="../excel/disease_pathway.txt">
                <i class="el-icon-download"></i></el-link></el-tooltip>
            </span>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12" class="right-col">
        <myPie :seriesData="pieData" :legend="legend" :infoId="infoId"></myPie>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" :gutter="60">
      <el-col :span="12" class="left-col">
        <my-bar
          :xAxisData="xAxisDataAge"
          :seriesData="seriesDataAge"
          :infoId="infoId"
          mapId="barMapId1"
          titleText="Age distribution"
          xAxisText="Age"
          yAxisText="Number of patients"
        >
        </my-bar>
      </el-col>
      <el-col :span="12" class="right-col">
        <my-bar
          :xAxisData="xAxisDataRR"
          :seriesData="seriesDataRR"
          :infoId="infoId"
          mapId="barMapId2"
          titleText="RR distribution"
          xAxisText="RR"
          yAxisText="Number of multimorbidities"
        >
        </my-bar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mybar from "../charts/bar";
import myPie from "../charts/pie";
export default {
  props: {
    infoId: {
      type: Number,
      default: 1,
    },
    totalData: {
      type: Object,
      default: () => {
        return {
          totalPatients: 1314,
          totalComorbidity: 31,
          prevalence: "0.32%",
          diseaseSNPs: 0,
          diseaseGenes: 2,
          diseasePPI: 5,
          diseasePathways: 0,
          // 统计信息结束，柱状图信息
          xAxisDataAge: [
            "1-2",
            "2-3",
            "3-4",
            "4-5",
            "5-6",
            "7-8",
            "8-9",
            "10-MAX",
          ],
          seriesDataAge: [1, 39, 133, 329, 511, 301],
          xAxisDataRR: ["20-29", "30-39", "40-49", "50-59", "60-69", "70-79"],
          seriesDataRR: [2, 11, 9, 2, 3, 2, 1, 1],
          // 扇形图信息
          legend: ["male", "female"],
          pieData: [
            {
              name: "Female",
              value: "55",
            },
            {
              name: "Male",
              value: "45",
            },
          ],
        };
      },
    },
  },
  watch: {
    totalData: {
      handler(newValue) {
        this.refreshData(newValue);
      }
    }
  },
  components: {
    "my-bar": mybar,
    myPie: myPie,
  },
  data: () => {
    return {
      // 统计信息
      totalPatients: 1314,
      totalComorbidity: 31,
      prevalence: '0.32%',
      diseaseSNPs: 0,
      diseaseGenes: 2,
      diseasePPI: 5,
      diseasePathways: 0,
      // 统计信息结束，柱状图信息
      xAxisDataAge: ['1-2','2-3','3-4','4-5','5-6','7-8','8-9','10-MAX'],
      seriesDataAge: [1,39,133,329,511,301],
      xAxisDataRR: ['20-29','30-39','40-49','50-59','60-69','70-79'],
      seriesDataRR: [2,11,9,2,3,2,1,1],
      // 扇形图信息
      legend: ['male', 'female'],
      pieData: [
        {
          name: "Female",
          value: "55",
        },
        {
          name: "Male",
          value: "45",
        },
      ],
      downloadUrls: [
        {
          url: 'multimorbidity/excel/disease_snp_sig_ld.txt',
          name:'disease_snp_sig_ld.txt',
          description: 'Disease related SNPs',
        },
        {
          url: 'multimorbidity/excel/disease_gene.txt',
          name:'disease_gene.txt',
          description: 'Disease related genes',
        },
        {
          url: 'multimorbidity/excel/disease_ppi.txt',
          name:'disease_ppi.txt',
          description: 'Disease related PPIs',
        },
        {
          url: 'multimorbidity/excel/disease_pathway.txt',
          name:'disease_pathway.txt',
          description: 'Disease related pathways',
        }
      ]
    };
  },
  methods: {
    refreshData(res) {
      this.xAxisDataAge = res.xAxisDataAge
        .split(",")
        .map((item) => item.slice(1, -1));
      this.xAxisDataRR = res.xAxisDataRR.split(",");
      this.seriesDataAge = res.seriesDataAge.split(",");
      this.seriesDataRR = res.seriesDataRR.split(",");
      this.pieData = res.pieDataDto;
      this.diseaseGenes = res.statisticDataDto.diseaseGenes;
      this.diseasePPI = res.statisticDataDto.diseasePPI;
      this.diseasePathways = res.statisticDataDto.diseasePathways;
      this.diseaseSNPs = res.statisticDataDto.diseaseSNPs;
      this.prevalence =
        (res.statisticDataDto.prevalence * 100).toFixed(2) + "%";
      this.totalComorbidity = res.statisticDataDto.totalComorbidity;
      this.totalPatients = res.statisticDataDto.totalPatients;
    },
  },
  async mounted() {
    // let res = await getDiseaseData({
    //   id: this.infoId,
    // });
    // this.refreshData(res);
  },
};
</script>

<style lang="less" scoped>
.left-col {
  padding: 40px 0 0;
  span {
    display: block;
    margin: 10px 0;
    height: 24px;
    text-align: left;
  }
  .el-link{
    font-size:16px;
    font-weight: bolder;
  }
}
.right-col {
  padding: 40px 0 0;
  border-left: 1px solid #ccc;
}
.top-row {
  border-bottom: 1px solid #ccc;
}
.a-to-file{
  color: #535558;
  list-style-type: disc;
  :hover{
    color: #7d98b8;
  }
}

</style>

<template>
  <div>
    <div id="pieMain" style="min-height: 300px"></div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  props: {
    seriesData: {
      //纵坐标的值
      type: Array,
      default: () => [],
    },
    infoId: {
      type: Number,
      default: 1,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    option() {
      return {
        title: {
          text: "Sex ratio",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          data: this.legend
        },
        series: [
          {
            name: "sex",
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            data: this.seriesData,
            roseType: 'radius',
            label: {
              normal: {
                position: "inner",
                show: true,
                formatter: "{d}%",
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
          },
        ],
      };
    },
  },
  watch: {
    infoId: {
      handler() {
        this.refreshMap();
      },
    },
    seriesData: {
      handler() {
        this.refreshMap();
      }
    }
  },
  methods: {
    refreshMap() {
      var pieChart = echarts.init(document.getElementById("pieMain"));
      pieChart.resize();
      pieChart.setOption(this.option);
    }
  },
  mounted() {
    this.refreshMap();
  },
};
</script>

<style>

</style>
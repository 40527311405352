import axiosInstance from './index';
export function getBrowseMenuData() {
    return axiosInstance({
        url: '/browse/getBrowseMenuData',
        method: 'get',
    });
}
export function getDiseaseData(params) {
    return axiosInstance({
        url: '/browse/getDiseaseData',
        method: 'get',
        params,
    })
}
export function findByNameLike(params) {
    return axiosInstance({
        url: '/browse/findByNameLike',
        method: 'get',
        params,
    })
}
export function getBrowseDataById(params) {
    //（参数: String name, String category, double rr, String comorbidityName）
    return axiosInstance({
        url: '/browse/getBrowseDataById',
        method: 'get',
        params,
    })
}
export function getDescriptionById(params) {
    return axiosInstance({
        url: '/browse/getDescriptionById',
        method: 'get',
        params,
    })
}
export function getBrowseComorbidity(params) {
    return axiosInstance({
        url: '/browse/getBrowseComorbidity',
        method: 'get',
        params,
    })
}

// genetic tab 的关系图的输入部分
export function getInterpertableDataById(params) {
    return axiosInstance({
        url: '/browse/getInterpertableDataById',
        method: 'get',
        params,
    })
}

// genetic tab 的五个table的接口
// （参数均为：int id,String type,String input)
export function getSnp(params) {
    return axiosInstance({
        url: '/browse/getSnp',
        method: 'get',
        params,
    })
}
export function getPpi(params) {
    return axiosInstance({
        url: '/browse/getPpi',
        method: 'get',
        params,
    })
}
export function getPathway(params) {
    return axiosInstance({
        url: '/browse/getPathway',
        method: 'get',
        params,
    })
}
export function getGene(params) {
    return axiosInstance({
        url: '/browse/getGene',
        method: 'get',
        params,
    })
}
export function getRgAndP(params) {
    return axiosInstance({
        url: '/browse/getRgAndP',
        method: 'get',
        params,
    })
}

<template>
  <div class="tab-box">
    <!--<div class="input-pane">
      <el-select v-model="mapType" placeholder="select one to filter">
        <el-option
          v-for="item in mapOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="demo-input-suffix">
        Input:
        <el-input v-model="mapInput" placeholder="SNP,gene,PPI or Pathway"></el-input>
      </div>
      <el-button type="primary" class="submit-btn" @click="handleSubmit">submit</el-button>
      <el-button type="warning" @click="handleReset" class="reset-btn">
          reset
        </el-button>
    </div>-->
    <div>
      <relationmap
        :browseData="mapData"
        relationMapId="relationmap2"
        :infoName="infoName"
        :highLightNodeName="highLightNodeName"
      ></relationmap>
    </div>
    <div><h4 class="label-h4">Filter by:</h4></div>
    <div class="input-pane">
      <el-select v-model="type" placeholder="select one to filter">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="demo-input-suffix">
        Input:
        <el-input v-model="input"></el-input>
        <el-button type="primary" class="submit-btn" @click="handleSubmitTable">submit</el-button>
      </div>
    </div>
    <el-tabs
      tab-position="left"
      class="table-box"
      @tab-click="handleClickTableTabs"
      v-model="activeName"
      v-loading="loadingTableTabs"
    >
      <el-tab-pane label="By SNP" name="tableDataSNPs"
        ><tab-table :tableData="tableDataSNPsData" tabType="tableDataSNPsData"></tab-table
      ></el-tab-pane>
      <el-tab-pane label="By Gene" name="tableDataGenes"
        ><tab-table :tableData="tableDataGenes"></tab-table
      ></el-tab-pane>
      <el-tab-pane label="By PPI" name="tableDataPPIs"
        ><tab-table :tableData="tableDataPPIs"></tab-table
      ></el-tab-pane>
      <el-tab-pane label="By Pathway" name="tableDataPathways"
        ><tab-table :tableData="tableDataPathways"></tab-table
      ></el-tab-pane>
      <el-tab-pane label="By Genetic Correlation" name="tableDataRgp"
        ><tab-table :tableData="tableDataRgp"></tab-table
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import table from "../common/table";
import relationmap from "../charts/relationship";
import { getSnp, getPpi, getPathway, getGene, getRgAndP, getInterpertableDataById} from '../../api/browse'
export default {
  props: {
    infoId: {
      type: Number,
      default: 1,
    },
    geneticInterComTabMapData: {
      type: Object,
      default: () => {
        return {
          node: [],
          edge: []
        }
      }
    },
    tableDataSNPs: {
      type: Array,
      default: () => [{}]
    }
  },
  computed: {
    infoName() {
      return this.$route.query.name
    },
    mapData: {
      get() { return this.geneticInterComTabMapData;},
      set(value) {
        return value;
      }
    },
    tableDataSNPsData: {
      get() {return this.tableDataSNPs},
      set(value) {return value}
    }
  },
  data: () => {
    return {
      highLightNodeName: [],
      loadingTableTabs: true,
      activeName: 'tableDataSNPs',
      input: null,
      mapInput: null,//关系图上方的输入框
      type: null,// 下拉列表的值
      mapType: null,
      tableDataGenes: [{}],
      tableDataPPIs: [{}],
      tableDataPathways: [{}],
      tableDataRgp: [{}],
      baseOptions: [
        {
          value: "disease2",
          label: "Disease ID",
        },
        {
          value: "description2",
          label: "Disease Description",
        },
      ],
      options: [
        {
          value: "disease2",
          label: "Disease ID",
        },
        {
          value: "description2",
          label: "Disease Description",
        },
        {
          value: "snp",
          label: "SNP",
        }
      ],
      mapOptions: [
        {
          value:'snp',
          label: 'snp',
        },
        {
          value:'gene',
          label: 'gene',
        },
        {
          value: 'ppi',
          label: 'ppi',
        },
        {
          value: 'pathway',
          label: 'pathway',
        },
      ]
    };
  },
  components: {
    "tab-table": table,
    relationmap: relationmap,
  },
  methods: {
    handleSubmitTable() {
      console.log("this.activeName");
      //this.activeName = 'tableDataSNPs';
      //this.getSnp(this.infoId, this.type, this.input);
      switch(this.activeName) {// 更新table的数据
        case 'tableDataSNPs':
          this.getSnp(this.infoId, this.type, this.input);
          break;
        case 'tableDataGenes':
          this.getGene(this.infoId, this.type, this.input);
          break;
        case 'tableDataPPIs':
          this.getPpi(this.infoId, this.type, this.input);
          break;
        case 'tableDataPathways':
          this.getPathway(this.infoId, this.type, this.input);
          break;
        case 'tableDataRgp':
          this.getRgAndP(this.infoId, this.type, this.input);
          break;
      }
    },
    async handleSubmit() {
      // 处理关系图上方的输入数据
      let params = {
        id: this.infoId,
        type: this.mapType,
        input: this.mapInput
      }
      let res = await getInterpertableDataById(params);
      console.log("handleSubmit  res:", res);
      // this.mapData = res;
      this.highLightNodeName = res.node;
    },
    switchSelectValue(name) {
      switch(name) {
        case 'tableDataSNPs':
          this.options = this.baseOptions
          break;
        case 'tableDataGenes': 
          this.options = this.baseOptions.concat([
            {
              value: 'geneid',
              label: 'Gene ID',
            },{
              value: 'genesymbol',
              label: 'Gene Symbol',
            },
          ])
        break;
        case 'tableDataPPIs': 
         this.options = this.baseOptions.concat([
            {
              value: "ppiid",
              label: "PPI (ID)",
            },
            {
              value: "ppisymbol",
              label: "PPI (Symbol)",
            }
          ]);
          break;
        case 'tableDataPathways': 
           this.options = this.baseOptions.concat([
            {
              value:"pathway",
              label: "Pathway",
            },
          ])
          break;
        case 'tableDataRgp':
         this.options = this.baseOptions
          break;
      }
    },
    handleClickTableTabs(tab) {
      let name = tab.name;
      this.loadingTableTabs = true;
      //清空输入框
      this.type = null;
      this.input = null;
      this.switchSelectValue(name);// 根据label会切换下拉列表的值
      switch(name) {// 更新table的数据
        case 'tableDataSNPs':
          this.getSnp(this.infoId, this.type, this.mapInput);
          break;
        case 'tableDataGenes': 
          this.getGene(this.infoId, this.type, this.mapInput);
          break;
        case 'tableDataPPIs': 
          this.getPpi(this.infoId, this.type, this.mapInput);
          break;
        case 'tableDataPathways': 
          this.getPathway(this.infoId, this.type, this.mapInput);
          break;
        case 'tableDataRgp': 
          this.getRgAndP(this.infoId, this.type, this.mapInput);
          break;
      }
      this.loadingTableTabs = false;
    },
    // 以下是获取table数据的5个方法
    async getSnp(id, type, input) {
      console.log(id, type, input);
      let params = {
        id: id,
        type: type,
        input: input
      };
      let res = await getSnp(params);
      console.log("getSnp res:", res);
      this.tableDataSNPsData = res;
      console.log("this.tableDataSNPsData:", this.tableDataSNPsData);
    },
    async getPpi(id, type, input) {
      let params = {
        id: id,
        type: type,
        input: input
      };
      let res = await getPpi(params);
      console.log("getPpi res:", res);
      this.tableDataPPIs = res;
    },
    async getPathway(id, type, input) {
      let params = {
        id: id,
        type: type,
        input: input
      };
      let res = await getPathway(params);
      console.log("getPathway res:", res);
      this.tableDataPathways = res;
    },
    async getGene(id, type, input) {
       let params = {
        id: id,
        type: type,
        input: input
      };
      let res = await getGene(params);
      console.log("getGene res:", res);
      this.tableDataGenes = res;
    },
    async getRgAndP(id, type, input) {
       let params = {
        id: id,
        type: type,
        input: input
      };
      let res = await getRgAndP(params);
      console.log("getRgAndP res:", res);
      this.tableDataRgp = res;
    },
    handleReset() {
      // 将颜色变成原来的样子
      this.input = "";
      this.highLightNodeName = [];
    }
  },
  mounted() {
    // 页面加载时,
    // this.activeName = 'tableDataSNPs';
    this.getSnp(this.infoId, this.type, this.input);
    this.loadingTableTabs = false;
  },
  watch: {
    infoId: {
      async handler(newValue) {
        this.activeName = 'tableDataSNPs';
        this.loadingTableTabs = true;
        this.getSnp(newValue, this.type, this.input);
        this.loadingTableTabs = false;
      },
    },
  }
};
</script>

<style lang="less" scoped>
.input-pane {
  padding: 20px 0;
  text-align: left;
  .el-input {
    width: 300px;
  }
}
.el-input {
  margin-bottom: 20px;
}
.demo-input-suffix {
  margin-left: 20px;
  display: inline-block;
}
.submit-btn{
  margin-left: 20px;
}
.label-h4 {
  line-height: 15px;
  text-align: left;
  margin: 0;
}
</style>
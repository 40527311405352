<template>
  <div>
    <h2>{{ nameDesc }}</h2>
    <el-tabs
      class="browse-tab-item"
      v-model="activeName"
      @tab-click="handleClick"
      stretch
    >
      <el-tab-pane label="Disease" name="disease">
        <disease-tab
          :infoId="infoId"
          :totalData="totalData"
          v-loading="loadingDisease"
        ></disease-tab>
      </el-tab-pane>
      <el-tab-pane label="Multimorbidity" name="comorbidity">
        <comorbidity-tab
          :infoId="infoId"
          :comorbidityTabMapData="comorbidityTabMapData"
          :comorbidityTabTableData="comorbidityTabTableData"
          v-loading="loadingComorbidity"
        ></comorbidity-tab>
      </el-tab-pane>
      <el-tab-pane label="Genetic interpretable multimorbidity" name="geneticInterCom">
        <geneticInterCom-tab
          :infoId="infoId"
          :geneticInterComTabMapData="geneticInterComTabMapData"
          :tableDataSNPs="tableDataSNPs"
          v-loading="loadingGeneticInterCom"
        ></geneticInterCom-tab>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import comorbidityTab from "../browse/comorbidityTab";
import diseaseTab from "../browse/diseaseTab";
import geneticInterComTab from "../browse/geneticInterComTab";
import {
  getBrowseDataById,
  getDescriptionById,
  getBrowseComorbidity,
  getDiseaseData,
  getSnp,
  getInterpertableDataById
} from "../../api/browse";

export default {
  computed: {
    infoId() {
      return this.$route.query.id ? parseInt(this.$route.query.id) : 1;
    }
  },
  data: () => {
    return {
      activeName: "disease",
      nameDesc: "",
      // disease tab
      totalData: {}, //disease tab 的所有信息
      loadingDisease: true, //disease tab 是否正在加载
      // comorbidity tab
      comorbidityTabMapData: {
        // 关系图数据
        node: [],
        edge: [],
      },
      comorbidityTabTableData: [{}], // table 数据
      loadingComorbidity: true, //comorbidity tab 是否正在加载
      getBrowseDataByIdData: null, // 用来保存第2,3tab 的相同的关系图的数据
      // geneticInterCom tab
      geneticInterComTabMapData: {
        // 关系图数据
        node: [],
        edge: [],
      },
      tableDataSNPs: [{}],//第一个tab的数据
      loadingGeneticInterCom: true, // 是否正在加载
    };
  },
  components: {
    "comorbidity-tab": comorbidityTab,
    "disease-tab": diseaseTab,
    "geneticInterCom-tab": geneticInterComTab,
  },
  watch: {
    infoId: {
      async handler(newValue) {
        this.loadingDisease = true;
        this.activeName = 'disease';
        this.getDiseaseTabData(newValue); //每次切换infoId,都会跳转回tab1，并且更新tab1的数据
        this.nameDesc = await getDescriptionById({
          id: newValue,
        });
        this.getBrowseDataByIdData = null;
      },
    },
  },
  methods: {
    async getDiseaseTabData(infoId) {
      //获得disease tab 的所有信息
      this.loadingDisease = true;
      let res = await getDiseaseData({
        id: infoId,
      });
      this.totalData = res;
      this.loadingDisease = false;
    },
    async getComorbidityTabData(infoId) {
      // 依据infoId 获取到关系图的数据和table的数据
      this.loadingComorbidity = true;
      this.getBrowseDataByIdData = await getBrowseDataById({
        id: infoId,
      });
      this.comorbidityTabMapData = this.getBrowseDataByIdData;
      let tableData = await getBrowseComorbidity({
        id: infoId,
      });
      this.comorbidityTabTableData = tableData;
      this.loadingComorbidity = false;
    },
    async getGeneticInterComTabData(infoId) {
      // 依据infoId获取到第三个tab的数据，包括关系图和5个 table
      this.loadingGeneticInterCom = true;
      // if (this.getBrowseDataByIdData) {
      //   this.geneticInterComTabMapData = this.getBrowseDataByIdData;
      // } else {
      //   let res = await getBrowseDataById({
      //     id: infoId,
      //   });
      //   this.getBrowseDataByIdData = res;
      //   this.geneticInterComTabMapData = res;
      // }
      let res = await getInterpertableDataById({
        id: infoId,
      });
      this.geneticInterComTabMapData = res;
      // table第一个tab的数据
      let params = {
        id: this.infoId,
        type: "null",
        input: "null"
      };
      res = await getSnp(params);
      this.tableDataSNPs = res;
      this.loadingGeneticInterCom = false;
    },
    handleClick(tab) {
      // 点击tab之后再发送请求，通过父子组件通信的方式传递给相关组件
      let name = tab.name;
      switch (name) {
        case "disease":
          this.getDiseaseTabData(this.infoId);
          break;
        case "comorbidity":
          this.getComorbidityTabData(this.infoId);
          break;
        case "geneticInterCom":
          this.getGeneticInterComTabData(this.infoId);
          break;
      }
    },
  },
  async mounted() {
    this.nameDesc = await getDescriptionById({
      id: this.infoId,
    });
    this.getDiseaseTabData( this.infoId);
  },
};
</script>

<style lang="less">
.tab-box {
  min-height: 200px;
}
.browse-tab-item .el-tabs__item {
  font-size: 18px;
}
</style>

<template>
  <div>
    <div :id="mapId" style="min-height: 500px"></div>
  </div>
</template>

<script>
var echarts = require("echarts");
export default {
  props: {
    infoId: {
      type: Number,
      default: 1,
    },
    mapId: {
      type: String,
      default: "",
    },
    xAxisText: {
      type: String,
      default: "",
    },
    yAxisText: {
      type: String,
      default: "",
    },
    xAxisData: {
      //横坐标的名称
      type: Array,
      default: () => [],
    },
    seriesData: {
      //纵坐标的值
      type: Array,
      default: () => [],
    },
    titleText: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {};
  },
  computed: {
    option() {
      return {
        color: ["#3398DB"],
        title: {
          text: this.titleText,
          left: "center",
          top: "top",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "12%",
          right: "10%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            name: this.xAxisText,
            nameLocation: "end",
            nameTextStyle: {
              fontWeight:"bold"
            },
            type: "category",
            data: this.xAxisData,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval:0
            },
          },
        ],
        yAxis: [
          {
            name: this.yAxisText,
            nameLocation: "end",
            nameTextStyle: {
              fontWeight:"bold"
            },
            type: "value",
          },
        ],
        series: [
          {
            name: "num",
            type: "bar",
            barWidth: "60%",
            data: this.seriesData,
          },
        ],
      };
    },
  },
  methods: {
    refreshMap() {
      var barChart = echarts.init(document.getElementById(this.mapId));
      barChart.resize();
      barChart.setOption(this.option);
    }
  },
  watch: {
    infoId: {
      async handler() {
        this.refreshMap();
      },
    },
    xAxisData: {
      handler() {
        this.refreshMap();
      }
    }
  },
  mounted() {
    this.refreshMap();
  }
};
</script>

<style>

</style>
<template>
  <div class="tab-box">
    <div class="right-col">
      <!--<div class="input">
        Search:
        <el-autocomplete
          class="inline-input"
          v-model="mapInput"
          :fetch-suggestions="querySearch"
          placeholder="disease,ICD 10 code or name"
          :trigger-on-focus="false"
          @select="handleSelect"
          style="width: 400px;margin-right: 20px"
        ></el-autocomplete>
        <el-button type="warning" @click="handleReset" class="reset-btn">
          reset
        </el-button>
      </div>-->
      <div class="relationmap">
        <relationmap
          :browseData="comorbidityTabMapData"
          relationMapId="relationmap1"
          :infoName="infoName"
          :highLightNodeName="highLightNodeName"
        ></relationmap>
      </div>
    </div>
    <tab-table :tableData="handleTableData(comorbidityTabTableData)" :ifFilter="true"></tab-table>
  </div>
</template>
<script>
import table from "../common/table";
import relationmap from "../charts/relationship";
import { findByNameLike} from "../../api/browse";
export default {
  computed: {
    infoName() {
      return this.$route.query.name
    }
  },
  props: {
    infoId: {
      type: Number,
      default: 1,
    },
    comorbidityTabTableData: {
      type: Array,
      default: () => [{}]
    },
    comorbidityTabMapData: {
      type: Object,
      default: () => {
        return {
          node: [],
          edge: []
        }
      }
    }
  },
  data: () => {
    return {
      mapInput: "", //关系图上方的输入框
      highLightNodeName: [],
    };
  },
  components: {
    "tab-table": table,
    "relationmap": relationmap,
  },
  methods: {
    async querySearch(queryString, cb) {
      // 输入的为queryString，返回的是result,cb(result)
      // 这里需要进行查询，返回搜索到的结果。
      let resultArr = [];
      let res = queryString
        ? await findByNameLike({
            name: queryString,
          })
        : null;
      if(res.length === 0) {
        console.log("返回的数组为空");
      }
      res.map((item) => {
        if (item.name === queryString) {
          resultArr.push({
            value: item.description,
            id: item.name,
          });
        }
      });
      cb(resultArr);
    },
    async handleSelect(item) {
      let name = item.id;// 获得点选的疾病id，接着就获得关系图所需数据
      console.log(name);
      // 修改关系图的数据 code === id 的颜色不变，其它颜色变为灰色。
      this.highLightNodeName = [{code: name}];
    },
    handleTableData(tableData) {
      let newTableData = tableData.map(item => {
        return {
          diseaseId1: item.diseaseId1,
          diseaseId2: item.diseaseId2,
          description1: item.description1,
          description2: item.description2,
          i_value_i: item.i_value_i,
          i_value_j: item.i_value_j,
          coCountValue: item.coCountValue,
          cSimultineously: item.cSimultineously,
          rr: item.rr,
          pValue: item.pValue,
          category1: item.category1,
          category2: item.category2,
          direction:  item.direction,
          n1: item.n1,
          n2: item.n2,
          p1Direction: item.p1Direction,
          p2Direction: item.p2Direction
        }
      });
      return newTableData;
    },
    handleReset() {
      // 将颜色变成原来的样子
      this.mapInput = "";
      this.highLightNodeName = [];
    }
  },
}; 
</script>
<style lang="less" scoped>
.right-col {
  .input {
    padding: 20px 0;
    text-align: left;
    .el-input {
      width: 300px;
    }
  }
  .relationmap {
    min-height: 400px;
    border: 1px solid #ccc;
  }
}
.reset-btn {
  float: right;
}
</style>
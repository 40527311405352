<template>
<div>
  <div class="no-table" v-if="tableData.length === 0">
    No matched data.
  </div>
  <div class="table" v-else>
    <el-table
      :data="
        nowData
      "
      style="width: 100%"
      border
      @cell-click="handleCellClick"
      @sort-change="sortChange"
    >
      <el-table-column
        v-for="columnKey in Object.keys(tableData[0])"
        :key="columnKey"
        :prop="columnKey"
        :label="columnKeyObj[columnKey].label"
        :width="columnKeyObj[columnKey].width"
        :sortable="columnKeyObj[columnKey].sortable"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalPages"
    >
    </el-pagination>
  </div>
</div>
</template>

<script>
import {columnKeyObj} from '../../data/table'
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [{}],
    },
    ifFilter: {
      type: Boolean,
      default: true,
    },
    tabType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      columnKeyObj: columnKeyObj, //用来确定每一列数据的各种操作类型（筛选、排序）
      // 页面总条数 totalPages: 4,
      currentPage: 1, //当前页码
      pageSize: 5, // 每页数据条数
      columnParams: {
        // 记录用来传递给父组件的参数
        sortColumnName: null, //若未选则为null
        sortColumnValue: null,
        filterColumnName: null,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      },
    };
  },
  computed: {
    totalPages() {
      // 数据总条数
      return this.tableData.length;
    },
    nowData: {
      get() {
        return this.tableData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      },
      set(value){
        return value
      }
    }
  },
  methods: {
    sortChange({column, order, prop}) {
      console.log(column);
      if(order === 'descending') {
        this.tableData.sort((a, b) => b[prop] - a[prop])
      } else {
        this.tableData.sort((a, b) => a[prop] - b[prop])
      }
      this.currentPage = 1;
      for(let i=(this.currentPage - 1) * this.pageSize;i<this.currentPage * this.pageSize;i++){
        this.$set(this.nowData, i, this.tableData[i]);
      }
    },
    handleCellClick(row,column) {
      if(this.tabType === 'tableDataSNPsData' && column.label === 'snp') {
        this.$router.push('/downloads');
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      // 子组件改变每页的条数
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
};
</script>

<style>
.table {
  margin: 20px 0 80px;
  width: 100%;
}
.el-pagiantion {
  margin: 20px;
}
.no-table {
  margin: 40px 0 80px;
  height: 100px;
  line-height: 100px;
  width: 100%;
}
.snp-column {
   cursor: pointer;
   background-color: pink;
}
</style>
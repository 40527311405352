export var columnKeyObj = {
    diseaseId1: {
        sortable: false,
        fixed: true,
        width: "100",
        label: "Disease ID1",
        resizable: true,
    },
    diseaseId2: {
        sortable: false,
        fixed: true,
        width: "100",
        label: "Disease ID2",
        resizable: true,
    },
    description1: {
        sortable: false,
        fixed: true,
        width: "150",
        label: "Description1",
        resizable: true,
    },
    description2: {
        sortable: false,
        fixed: true,
        width: "150",
        label: "Description2",
        resizable: true,
    },
    i_value_i: {
        sortable: "custom",
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        // filterMethod: () => {

        // },
        width: "90",
        label: "I1",
        fixed: false,
        resizable: true,
    },
    i_value_j: {
        sortable: "custom",
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "90",
        label: "I2",
        fixed: false,
        resizable: true,
    },
    coCountValue: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "90",
        label: "C12",
        fixed: false,
        resizable: true,
    },
    cSimultineously: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "175",
        label: "C<sub>simultaneously</sub>",
        fixed: false,
        resizable: true,
    },
    rr: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "80",
        label: "RR",
        fixed: false,
        resizable: true,
    },
    pValue: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "110",
        fixed: false,
        label: "P value",
        resizable: true,
    },
    category1: {
        width: "120",
        fixed: false,
        label: "Category1",
        resizable: true,
    },
    category2: {
        width: "120",
        fixed: false,
        label: "Category2",
        resizable: true,
    },
    direction: {
        width: "120",
        fixed: false,
        label: "Direction",
        resizable: true,
    },
    n1: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "80",
        fixed: false,
        label: "N1",
        resizable: true,
    },
    n2: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "80",
        fixed: false,
        label: "N2",
        resizable: true,
    },
    p1Direction: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "150",
        fixed: false,
        label: "P1 Direction",
        resizable: true,
    },
    p2Direction: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "150",
        fixed: false,
        label: "P2 Direction",
        resizable: true,
    },
    snpOverlap: {
        sortable: false,
        width: "120",
        fixed: false,
        label: "Shared SNPs",
        resizable: true,
    },
    geneOverlapGeneId: {
        sortable: false,
        width: "160",
        fixed: false,
        label: "Shared genes (ID)",
        resizable: true,
    },
    ppiOverlapGeneId: {
        sortable: false,
        width: "180",
        fixed: false,
        label: "Shared PPIs (ID)",
        resizable: true,
    },
    ppiOverlapGeneSymbol: {
        sortable: false,
        width: "250",
        fixed: false,
        label: "Shared PPIs (Symbol)",
        resizable: true,
    },
    pathwayOverlap: {
        sortable: false,
        width: "120",
        fixed: false,
        label: "Shared pathways",
        resizable: true,
    },
    rg: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "80",
        fixed: false,
        label: "Rg",
        resizable: true,
    },
    p: {
        sortable: 'custom',
        // filters: [
        //     {
        //         text: ">10",
        //         value: 10,
        //     },
        //     {
        //         text: ">100",
        //         value: 100,
        //     },
        // ],
        width: "80",
        fixed: false,
        label: "P value",
        resizable: true,
    },
    geneOverlapGeneSymbol: {
        sortable: false,
        width: "150",
        fixed: false,
        label: "Shared genes (Symbol)",
        resizable: true,
    }
};